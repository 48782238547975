import { Component, OnInit } from '@angular/core';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { Title, Meta } from '@angular/platform-browser';
import { global } from '../../services/global';

// Models
import { ArrayRrss } from 'src/app/models/global.model';
import { CardInfo } from 'src/app/models/languages.model';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
  providers: [ScrollToService]
})
export class InicioComponent implements OnInit {

  public arrayRrss: Array<ArrayRrss>;
  public arrayLanguage: Array<CardInfo>;
  public arrayFrameworks: Array<CardInfo>;

  constructor(
    private titleService: Title,
    private meta: Meta
  ) {
    this.arrayRrss = global.arrayRrss;

    this.arrayLanguage = [
      { 
        "name": "Html", 
        "img": "assets/images/logos/logo-html.webp", 
        'alt': "Lenguaje Html" 
      },
      { 
        "name": "Css", 
        "img": "assets/images/logos/logo-css.webp", 
        'alt': "Lenguaje Css" 
      },
      { 
        "name": "Javascript", 
        "img": "assets/images/logos/logo-js.webp", 
        'alt': "Lenguaje Javascript" 
      },
      { 
        "name": "TypeScript", 
        "img": "assets/images/logos/logo-typescript.webp", 
        'alt': "Lenguaje Typescript" 
      },
      { 
        "name": "php", 
        "img": "assets/images/logos/logo-php.webp", 
        'alt': "Lenguaje php" 
      },
      { 
        "name": "Shell/Bash", 
        "img": "assets/images/logos/logo-bash.svg", 
        'alt': "Terminal/Shell/Bash" 
      },
      { 
        "name": "MySQL", 
        "img": "assets/images/logos/logo-mysql.webp", 
        'alt': "Base de datos MySql" 
      },
      { 
        "name": "MongoDB", 
        "img": "assets/images/logos/logo-mongodb.webp", 
        'alt': "Base de datos MongoDB" 
      },
      /* {
        "name": "Java", 
        "img": "assets/images/logos/logo-android.webp", 
        'alt':"Lenguaje Java"
      },
      {
        "name": "Swift", 
        "img": "assets/images/logos/logo-apple.webp", 
        'alt':"Lenguaje Swift (apple)"
      } */
    ];

    this.arrayFrameworks = [
      { 
        "name": "Angular 4+", 
        "img": "assets/images/logos/logo-angular.webp", 
        'alt': "Framework Angular" 
      },
      { 
        "name": "Bootstrap", 
        "img": "assets/images/logos/logo-bootstrap.webp", 
        'alt': "Framework Bootstrap" 
      },
      { 
        "name": "jQuery", 
        "img": "assets/images/logos/logo-jquery.webp", 
        'alt': "Framework jQuery" 
      },
      { 
        "name": "TailwindCSS", 
        "img": "assets/images/logos/logo-tailwindcss.svg", 
        'alt': "Framework Sass" 
      },
      { 
        "name": "Sass", 
        "img": "assets/images/logos/logo-sass.webp", 
        'alt': "Framework Sass" 
      },
      { 
        "name": "Node & ExpressJS", 
        "img": "assets/images/logos/logo-expressjs.webp", 
        'alt': "Framework Node & ExpressJS" 
      },
      /* {
        "name": "Grid & Flex", 
        "img": "assets/images/logos/logo-layout.webp", 
        'alt':"Framework css flex & grid layout"}, 
        */
      { 
        "name": "Ionic", 
        "img": "assets/images/logos/logo-ionic.webp", 
        'alt': "Framework Ionic" 
      },
      { 
        "name": "Laravel", 
        "img": "assets/images/logos/logo-laravel.webp", 
        'alt': "Framework Laravel php" 
      },
      /* {
        "name": "Symfony", 
        "img": "assets/images/logos/logo-symfony.webp", 
        'alt':"Framework Symfony php"
      } */
    ];
  }

  ngOnInit(): void {
    this.titleService.setTitle("Inicio");
    this.meta.updateTag({ name: 'keywords', content: 'Programador web, programador móvil, informático, técnico informático, la web del programador, tecnico en informatica, reparacion de ordenadores, diseño web, diseño de paginas web, diseño responsive' });
    this.meta.updateTag({ name: 'description', content: 'Soy Toni Torres, programador web y móvil, fullstack. Web personal donde puedes ver información sobre mi, proyectos, habilidades y redes sociales donde puedes encontrarme.' });
  }


}
