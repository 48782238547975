<div class="jumbotron jumbotron-fluid mt-5">
  <div class="container">
    <h1>
      ¿Que puedo hacer por ti?
    </h1>
    <p>
      Estoy esperando con ganas la propuesta que quieras hacerme.
    </p>
  </div>
</div>

<div id="container-contacto" class="container mt-3">
  <div class="row">
    <div class="col-md-6">
      <!-- <h2> Información de Contacto </h2> -->
      <div class="col-md">
        <i
          class="fas fa-phone contact-icons"
          title="Teléfono icono"
          aria-hidden="true"
        ></i>
        +34 645 319 080
      </div>
      <div class="col-md">
        <i
          class="far fa-paper-plane contact-icons"
          title="Correo icono"
          aria-hidden="true"
        ></i>
        <a href="mailto: tonitorres.dev@gmail.com" target="_blank">
          tonitorres.dev@gmail.com</a
        >
      </div>
      <div class="col-md">
        <i
          class="fas fa-map-marked-alt contact-icons"
          title="Mapa icono"
          aria-hidden="true"
        ></i>
        Cataluña, Barcelona
      </div>
    </div> 
    <!-- col-md-6 -->

    <div class="col-md-6">
      <h2 class="text-center">Enviame un Mensaje</h2>

      <form [formGroup]="emailForm" class="">
        <div class="form-row">
          <div class="col">
            <label class="" for="nombre"> Nombre: </label>
            <input
              type="text"
              class="form-control"
              id="nombre"
              placeholder="Introduce nombre"
              name="nombre"
              formControlName="nombre"
              required
            />
            <div
              class="ml-2"
              *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)"
            >
              <div *ngIf="nombre.errors.required">
                <small class="text-red"> Nombre obligatorio. </small>
              </div>
            </div>
          </div>
          <div class="col">
            <label class="" for="email"> Email: </label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Introduce email"
              name="email"
              formControlName="email"
            />
            <div
              class="ml-2"
              *ngIf="email.invalid && (email.dirty || email.touched)"
            >
              <div *ngIf="email.errors.required">
                <small class="text-red"> Email obligatorio. </small>
              </div>
              <div class="ml-2" *ngIf="email.errors.pattern">
                <small class="text-red"> Email esta mal escrito. </small>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <label class="" for="asunto"> Asunto: </label>
            <input
              type="text"
              class="form-control"
              id="asunto"
              placeholder="Introduce asunto"
              name="asunto"
              formControlName="subject"
            />
            <div
              class="ml-2"
              *ngIf="subject.invalid && (subject.dirty || subject.touched)"
            >
              <div *ngIf="subject.errors.required">
                <small class="text-red"> Asunto obligatorio. </small>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <label class="" for="mensaje"> Mensaje: </label>
            <textarea
              class="form-control"
              id="mensaje"
              placeholder="Introduce mensaje"
              name="mensaje"
              cols="5"
              rows="5"
              formControlName="mssg"
            ></textarea>
            <div
              class="ml-2"
              *ngIf="mssg.invalid && (mssg.dirty || mssg.touched)"
            >
              <div *ngIf="mssg.errors.required">
                <small class="text-red"> Mensaje obligatorio. </small>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="spinner" class="d-flex align-items-center m-3">
          <strong>Enviando...</strong>
          <div
            class="spinner-border ml-auto"
            role="status"
            aria-hidden="true"
          ></div>
        </div>
        <div
          *ngIf="isResultOk === 'enviado'"
          class="alert alert-success m-2"
          role="alert"
        >
          <i
            class="fa-solid fa-envelope-circle-check"
            title="Icono correo enviado correctamente"
            aria-hidden="true"
          ></i>
          El correo ha sido enviado correctamente.
        </div>
        <div
          *ngIf="isResultOk === 'nenviado'"
          class="alert alert-danger m-2"
          role="alert"
        >
          <i
            class="fa-regular fa-circle-xmark"
            title="Icono de error"
            aria-hidden="true"
          ></i>
          No se ha podido enviar el correo, intentalo más tarde o contacte a
          tonitorres.dev@gmail.com.
        </div>
        <div
          *ngIf="isResultOk === 'isEmpty'"
          class="alert alert-warning m-2"
          role="alert"
        >
          <i
            class="fa-solid fa-triangle-exclamation"
            title="Icono de precaución"
            aria-hidden="true"
          ></i>
          No se ha podido enviar el correo, todos los campos tienen que estar
          llenos.
        </div>
        <div
          *ngIf="isResultOk === 'isEmail'"
          class="alert alert-warning m-2"
          role="alert"
        >
          <i
            class="fa-solid fa-triangle-exclamation"
            title="Icono de precaución"
            aria-hidden="true"
          ></i>
          No se ha podido enviar el correo, el correo esta mal puesto.
        </div>
        <div class="form-row">
          <div class="col divEnviar">
            <button
              class="btn btn-primary"
              type="button"
              (click)="onSubmit()"
              [disabled]="!emailForm.valid"
            >
              Enviar
              <i
                class="fa-regular fa-paper-plane"
                title="Icono de enviar"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>

      </form>
    </div> <!-- col-md-6 -->
  </div> <!-- row -->
</div> <!-- container-contacto -->
