<footer id="footer">
    <div class="container">
      <div class="row">
        
        <div class="col-md-12 text-center col-lg">
          <!-- ! icons-container -->
          <div class="icons-container">
            <span class="rrss-span" *ngFor="let rrss of arrayRrss">
              <a class="text-decoration-none" href="{{ rrss.link }}" target="_blank" rel="noopener noreferrer">
                <div class="icon-box">
                  <i class="icon rounded {{rrssColor}} {{ rrss.icon }}" title="{{ rrss.alt }}" aria-hidden="true"></i>
                  <span>{{ rrss.description }}</span>
                </div>
              </a>
            </span>
          </div> <!-- ! icons-container -->
        </div>
  
        <div class="footer-info text-center col-md-12 col-lg">
          <p class="">
            Desarrollado por Toni Torres Cuenca {{ year }} &copy;
          </p>
        </div>
      </div>
    </div>
  </footer>
