import { Component } from '@angular/core';
import { global } from '../../services/global';
import { ArrayRrss } from 'src/app/models/global.model';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public arrayRrss: Array<ArrayRrss>;
  public year = new Date().getFullYear();

  constructor() { 
    this.arrayRrss = global.arrayRrss;
  }

}
