<div class="container text-white">
  <div class="row">
    <!-- ! welcome-container-->
    <div class="welcome-container col-12 text-center">
      <!-- ! info-container -->
      <div class="info-container">
        <h1 class="brand">Toni Torres</h1>
        <h2 class="">Programador Web y Móvil</h2>
        <h3 class="">FullStack</h3>

        <!-- ! icons-container -->
        <div class="icons-container">
          <span class="rrss-span" *ngFor="let rrss of arrayRrss">
            <a class="text-decoration-none" href="{{ rrss.link }}" target="_blank" rel="noopener noreferrer">
              <div class="icon-box">
                <i class="icon rounded {{rrssColor}} {{ rrss.icon }}" title="{{ rrss.alt }}" aria-hidden="true"></i>
                <span>{{ rrss.description }}</span>
              </div>
            </a>
          </span>
        </div> <!-- ! icons-container -->

        <button class="glow-on-hover" type="button" [ngxScrollTo]="destinationRef">
          SABER MÁS
          <i class="ml-1 fas fa-chevron-down" title="Flecha icono" aria-hidden="true"></i>
        </button>

      </div> <!-- ! info-container -->
    </div> <!-- ! welcome-container-->
  </div> <!-- ! row -->

  <!-- ! About me -->
  <div id="destination" #destinationRef class="row col title-skills-it mt-5 just-center">
    <h2 class="color-secondary shadow-title title-size-extra"> 
      <strong> 
        Sobre Mi
      </strong> 
    </h2>
  </div>

  <div class="row">
    <div class="row sobremi mt-3">
      <div class="sobremi-text col-md-12 col-lg-6">
        <p>
          Programador serio y exigente. Soy crítico, honesto y detallista. Poca
          experiencia profesional pero con varios
          <a routerLink="/proyectos"> proyectos </a> personales que respaldan mi
          trabajo y conocimiento. Siempre en constante evolución personal /
          profesional, aprendiendo y buscando nuevos retos que afrontar.
        </p>

        <p>
          Me gusta trabajar en equipo e investigar los problemas del mundo
          haciendo que algo aparentemente complejo pase a ser simple y
          entendible para la gente.
        </p>

        <p>
          La constancia y la curiosidad por mi trabajo me han hecho explorar
          nuevos campos, llegar a sitios que nunca imaginé encontrar, conocer
          gente con la misma afición y objetivos, y conseguir la habilidad
          <!-- el superpoder -->
          de materializar mis ideas en algo que pueda llegar a la gente.
        </p>
      </div>

      <div class="foto-container col-md-12 col-lg-6">
        <img class="foto-toni rounded-circle" 
          src="assets/images/home/foto-perfil-toni.webp"
          alt="Toni Torres Cuenca foto perfil" 
          loading="lazy" 
          width="444" 
          height="380"
          />
      </div>
    </div>
  </div> <!-- ! About me -->

  <!-- ! Steve Jobs -->
  <div class="row col-md-12 mt-5 mb-5">
    <blockquote class="divCita blockquote">
      <p class="mb-0">
        Tu trabajo va a llenar gran parte de tu vida, la única manera de estar
        realmente satisfecho es hacer lo que creas que es un gran trabajo y la
        única manera de hacerlo es amar lo que haces.
      </p>
      <footer class="blockquote-footer">
        <cite title="Steve Jobs"> Steve Jobs </cite>
      </footer>
    </blockquote>
  </div> <!-- ! Steve Jobs -->

  <!-- ! It Language Skills -->
  <div class="row col title-skills-it mt-5 mb-3 just-center">
    <h2 class="color-secondary shadow-title title-size-extra">
      <strong> Habilidades IT </strong>
    </h2>
  </div>   
  <div id="divSkills" class="row mt-3 mb-5">
    <!-- LANGUAGES TEXT -->
    <div class="col-lg-6">
      <p>
        A lo largo de mi carrera he aprendido a desarrollar proyectos tanto en
        web como en móviles haciendo uso de diferentes técnicas y lenguajes de
        programación. Diseñar y administrar bases de datos, diseños responsive,
        hostings, herramientas y procesos como: control de versiones (Git/GitHub/Gitlab), 
        Trello, Notion, servidores, atención al cliente, e incluso cosas de 
        posicionamiento web (SEO).
      </p>
      <div id="btnSkills">
        <a href="./../../../assets/curriculum/CV_TONI_TORRES_CUENCA_ESP_2023.pdf" target="_blank" class="btn btn-info">
          Curriculum (CV)
          <i class="fas fa-file-download ml-2" title="descargar curriculum" aria-hidden="true"></i>
        </a>
        <a routerLink="/contacto" class="btn btn-info">
          CONTACTO
          <i class="fas fa-address-book ml-2" title="ir a contacto" aria-hidden="true"></i>
        </a>
        
      </div>
    </div>
    <!-- LANGUAGES SKILLS -->
    <div class="col-lg-6 lang-container">
      <div class="logos" *ngFor="let skill of arrayLanguage">
        <img src="{{ skill.img }}" alt="{{ skill.alt }}" 
          loading="lazy" class="tech-icon"
          width="120" height="124"
        />
        <p>{{ skill.name }} </p>
      </div>
    </div>
  </div> <!-- ! It Skills -->

  <!-- ! It Frameworks Skills -->
  <div class="row col mt-3 mb-3 just-center">
    <h2 class="color-secondary shadow-title title-size-extra">
      <strong>Frameworks</strong>
    </h2>
  </div>
  <div class="row">
    <div class="col frameworks-container">
      <div class="logos" *ngFor="let skill of arrayFrameworks">
        <img src="{{ skill.img }}" alt="{{ skill.alt }}" 
          loading="lazy" class="tech-icon" 
          width="120" height="124"
        />
        <p>{{ skill.name }} </p>
      </div>
    </div>
  </div> <!-- ! It Frameworks Skills -->

</div> <!-- ! MAIN CONTAINER -->