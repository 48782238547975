import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent implements OnInit {

  constructor(
    private titleService: Title,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle( "Proyectos" );
    this.meta.updateTag({name: 'keywords', content: 'Programador web, programador móvil, informático, técnico informático, la web del programador, tecnico en informatica, reparacion de ordenadores, diseño web, diseño de paginas web, diseño responsive, programador portfolio, proyectos de programador'});
    this.meta.updateTag({name: 'description', content: 'Soy Toni Torres, programador web y móvil, fullstack. Aquí puedes ver mis proyectos como un portfolio de cosas que he hecho.'});
  }

}
