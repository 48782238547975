<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
  <div class="container">
    <a class="navbar-brand mx-auto" href="#">
      Toni Torres
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" 
            routerLinkActive="active" 
            routerLink="inicio"
          >
            Inicio
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link"
            routerLinkActive="active"
            routerLink="proyectos"
          >
            Proyectos
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link"
              routerLinkActive="active"
              routerLink="contacto"
          >
            Contacto
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
