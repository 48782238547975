<div class="container mt-5">
  <div class="row">
    <div id="intro-proyectos" class="col-lg-12 text-white">
      <h1 class="color-secondary">
        <strong>
          Portafolio
        </strong>
      </h1>
      <p>
        Aquí puedes encontrar alguno de mis principales trabajos, si quieres ver
        más puedes ir a mi perfil de
        <a href="https://github.com/ToniTJK" target="_blank">GitHub</a>.
      </p>
    </div>
    <div class="col-lg"></div>
  </div>
  <div class="row">
    <div class="card col-md-4 col-lg" style="width: 400px;">
      <img
        class="card-img-top"
        src="assets/images/projects/havel-hakimi.webp"
        alt="Imagen de carta de proyecto Algortimo Havel-Hakimi Online"
        loading="lazy"
        width="232"
        height="200"
      />
      <div class="card-body">
        <h4 class="card-title">Havel-Hakimi</h4>
        <p class="card-text">Es un algoritmo codificado en Javascript puro.</p>
        <a
          href="https://havel-hakimi-online.tonitorrescuenca.com/"
          target="_blank"
          class="btn btn-primary"
          >Ver</a
        >
      </div>
    </div>
    <div class="card col-md-4 col-lg" style="width: 400px;">
      <img
        class="card-img-top"
        src="assets/images/projects/nottapp-logo.webp"
        alt="Imagen de carta de proyecto NotApp Aplicación"
        loading="lazy"
        width="232"
        height="200"
      />
      <div class="card-body">
        <h4 class="card-title">NottApp</h4>
        <p class="card-text">
          Aplicación en Play Store, realizado con Ionic 4.
        </p>
        <a
          href="https://play.google.com/store/apps/details?id=com.NottApp.nottApp&gl=ES"
          target="_blank"
          class="btn btn-primary"
          >Descargar</a
        >
      </div>
    </div>
    <div class="card col-md-4 col-lg" style="width: 400px;">
      <img
        class="card-img-top"
        src="assets/images/projects/mean-stack.webp"
        alt="Imagen de carta de proyecto con MEAN STACK"
        loading="lazy"
        width="232"
        height="200"
      />
      <div class="card-body">
        <h4 class="card-title">Notes App</h4>
        <p class="card-text">MongoDB, ExpressJS, Angular & NodeJS</p>
        <a
          href="https://notes.tonitorrescuenca.com"
          target="_blank"
          class="btn btn-primary"
          >Ver</a
        >
      </div>
    </div>
    <div class="card col-md-4 col-lg" style="width: 400px;">
      <img
        class="card-img-top"
        src="assets/images/projects/logo-tallerjaro.webp"
        alt="Imagen de carta de proyecto TalleresJaro Web"
        loading="lazy"
        width="232"
        height="200"
      />
      <div class="card-body">
        <h4 class="card-title">Talleres Jaro 2017</h4>
        <p class="card-text">Página web de un taller mecánico.</p>
        <a
          href="https://talleresjaro2017.com/"
          target="_blank"
          class="btn btn-primary"
          >Ver</a
        >
      </div>
    </div>
  </div>

  <div class="row">
    <div class="card col-md-4 col-lg" style="width: 400px;">
      <img
        class="card-img-top"
        src="assets/images/projects/ajax-ejemplo.webp"
        alt="Imagen de carta de proyecto Formulario de ejemplo"
        loading="lazy"
        width="232"
        height="200"
      />
      <div class="card-body">
        <h4 class="card-title">Ejem. Formulario</h4>
        <p class="card-text">Petición AJAX con formularió.</p>
        <a
          href="https://ajax-example.tonitorrescuenca.com/"
          target="_blank"
          class="btn btn-primary"
          >Ver</a
        >
      </div>
    </div>
    <div class="card col-md-4 col-lg" style="width: 400px;">
      <img
        class="card-img-top"
        src="assets/images/projects/juego-del-click.webp"
        alt="Imagen de carta de proyecto Juego del click"
        loading="lazy"
        width="232"
        height="200"
      />
      <div class="card-body">
        <h4 class="card-title">Juego del Click</h4>
        <p class="card-text">Juego web de capturar manzanas.</p>
        <a
          href="http://juego-del-click.tonitorrescuenca.com/"
          target="_blank"
          class="btn btn-primary"
          >Jugar</a
        >
      </div>
    </div>
    <div class="card col-md-4 col-lg" style="width: 400px;">
      <img
        class="card-img-top"
        src="assets/images/projects/reservas-hotel.webp"
        alt="Imagen de carta de proyecto Reservas de hotel"
        loading="lazy"
        width="232"
        height="200"
      />
      <div class="card-body">
        <h4 class="card-title">Reservas Hotel</h4>
        <p class="card-text">Panel de Administración de reservas de un hotel.</p>
        <a
          href="http://reservas-hotel.tonitorrescuenca.com/"
          target="_blank"
          class="btn btn-primary"
          >Ver</a
        >
      </div>
    </div>
    <div class="card col-md-4 col-lg" style="width: 400px;">
      <img
        class="card-img-top"
        src="assets/images/projects/github-logo.webp"
        alt="Imagen de carta de proyectos de github"
        loading="lazy"
        width="232"
        height="200"
      />

      <div class="card-body">
        <h4 class="card-title">Github</h4>
        <p class="card-text">Más proyectos y aportaciones.</p>
        <a
          href="https://github.com/ToniTJK"
          target="_blank"
          class="btn btn-primary"
          >Ver</a
        >
      </div>
    </div>
  </div>
</div>
