import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BackendService } from './../../services/backend.service';
import { Title, Meta } from '@angular/platform-browser';
import { EmailRequestStatus } from 'src/app/models/request-status.model';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
  providers: [BackendService],
})
export class ContactoComponent implements OnInit {
  public isResultOk: EmailRequestStatus;
  emailForm: UntypedFormGroup;
  spinner: boolean;

  constructor(
    private _BackendService: BackendService, 
    private titleService: Title,
    private meta: Meta
    ) {
    this.isResultOk = 'ok';
  }

  ngOnInit(): void {
    this.titleService.setTitle( "Contacto" );

    this.meta.updateTag({name: 'keywords', content: 'Programador web, programador móvil, informático, técnico informático, la web del programador, contactar programador, teléfono de contacto, contacto técnico informático'});
    this.meta.updateTag({name: 'description', content: 'Soy Toni Torres, programador web y móvil, fullstack. Aquí puedes contactar conmigo via formularió o ver mi información de contacto como el número de teléfono o el correo.'});

    this.emailForm = new UntypedFormGroup({
      nombre: new UntypedFormControl('', [
        Validators.required
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+[a-z]{2,4}$")
      ]),
      subject: new UntypedFormControl('', [
        Validators.required
      ]),
      mssg: new UntypedFormControl('', [
        Validators.required
      ])
    });
  }

  get nombre() { return this.emailForm.get('nombre'); }
  get email() { return this.emailForm.get('email'); }
  get subject() { return this.emailForm.get('subject'); }
  get mssg() { return this.emailForm.get('mssg'); }

  onSubmit() {
    this.isResultOk = 'ok';
    this.spinner = true;

    const params = {
      action: 'sendEmail',
      params: this.emailForm.value,
    };

    this._BackendService.sendEmail(params).subscribe(
      (result: EmailRequestStatus) => {
        this.spinner = false;

        switch (result) {
          case true:
            this.isResultOk = "enviado";
            break;
          case false:
            this.isResultOk = "nenviado";
            break;
          case 'isEmpty':
            this.isResultOk = 'isEmpty';
            break;
          case 'isEmail':
            this.isResultOk = 'isEmail';
            break;
          default:
            this.isResultOk = 'nenviado';
            break;
        }
      },
      (error) => {
        console.log(error);
        this.isResultOk = 'nenviado';
        this.spinner = false;
      }
    );
  }
}
