

<app-header></app-header>

<!-- * ANGULAR ROUTER -->
<div class="navbar-separation">
    <router-outlet (activate)="onActivate()"></router-outlet>
</div>

<app-footer></app-footer>
