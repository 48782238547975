import { Component, AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  constructor(
    private elementRef: ElementRef,
    private titleService: Title,
    private meta: Meta
    ) {}

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor =
      '#333B3F';
  }

  ngOnInit() {
    this.titleService.setTitle( "Inicio" );
    this.meta.addTag({name: 'keywords', content: 'Programador web, programador móvil, informático, técnico informático, la web del programador, tecnico en informatica, reparacion de ordenadores, diseño web, diseño de paginas web, diseño responsive'});
    this.meta.addTag({name: 'description', content: 'Soy Toni Torres, programador web y móvil, fullstack. Web personal donde puedes ver información sobre mi, proyectos, habilidades y redes sociales donde puedes encontrarme.'});
    this.meta.addTag({name: 'author', content: 'Toni Torres Cuenca'});
    this.meta.addTag({name: 'robots', content: 'index, follow'});
  }


  onActivate() {
    window.scroll(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }
}
