import { Global } from '../models/global.model';

export const global: Global = {
    arrayRrss: [
        {
          "alt": "Curriculum icono",
          "icon": "far fa-id-card", 
          "link": "./../../../assets/curriculum/CV_TONI_TORRES_CUENCA_ESP_2023.pdf",
          "description": 'CV'
        },
        {
          "alt": "Linkedin icono",
          "icon": "fab fa-linkedin", 
          "link": "https://www.linkedin.com/in/tonitorresprogramador/",
          "description": 'Linkedin'
        },
        {
          "alt": "GitHub icono",
          "icon": "fab fab fa-github", 
          "link": "https://github.com/ToniTorresDev",
          "description": 'Github'
        },
        {
          "alt": "Instagram icono",
          "icon": "fab fa-instagram", 
          "link": "https://www.instagram.com/tonitorres_dev/",
          "description": 'RRSS'
        }
      ],
};