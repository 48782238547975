<div class="jumbotron container mt-5 mb-5">
  <div class="container">
    <h1 class="display-3 text-danger font-weight-bold">Error 404!!</h1>
    <h2 class="display-5">Ooops...</h2>
    <p class="lead">Lo siento mucho, pero la página que busca no existe.</p>
    <hr class="my-4" />
    <h3 class="display-4">Otras opciones</h3>
    <p>Aquí le dejo las diferentes páginas que puede ser que busque.</p>
    <ul>
      <li>
        <a class="" routerLink="/inicio" role="button">Inicio</a>
      </li>
      <li>
        <a class="" routerLink="/proyectos" role="button">Proyectos</a>
      </li>
      <li>
        <a class="" routerLink="/contacto" role="button">Contacto</a>
      </li>
    </ul>
    <p>
      Si tiene alguna duda o problema por favor contactame en la página de
      <a class="" routerLink="/contacto" role="button">Contacto</a>
      o al correo:
      <a href="mailto: tonitorres.dev@gmail.com" target="_blank">
        tonitorres.dev@gmail.com</a
      >.
    </p>
  </div>
</div>
