import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';

import { map, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BackendService {

  private url:string;

  constructor(
    private _http: HttpClient
    ) {
      this.url = "https://tonitorrescuenca.com/backend/controller.php";
    }

  sendEmail(params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    });

    const httpOptions = {
      headers: headers,
    };

    return this._http
      .post(
        this.url,
        JSON.stringify(params),
        httpOptions
      )
      .pipe(
        timeout(59000),
        map((res) => res)
      );
  }
}
